import {Datagrid, List, TextField, FunctionField, ExportButton, TextInput, DateInput} from "react-admin";
import CustomDateField from "../../shared/components/CustomDateField";
import { useDataGridStyles } from "../../shared/styles/DataGridStyles";
import React from "react";
import {ListActions} from "../../shared/components/ListActions";

export const SettlementReports = (props) => {
    const classes = useDataGridStyles();

    const handleDownload = async (id) => {
        try {
            const response = await fetch(`/admin/settlement-reports/${id}/download`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to download file: ${response.statusText}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const contentDisposition = response.headers.get("Content-Disposition");
            const fileName = contentDisposition
                ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
                : "download";

            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Failed to download file. Please try again.");
        }
    };

    return (
        <List
            {...props}
            filters={filters}
            sort={{ field: "uploadDate", order: "DESC" }}
            perPage={25}
            bulkActionButtons={false}
            actions={<ListActions filters={filters} />}
        >
            <Datagrid classes={classes}>
                <TextField source="fileName" label="File Name" />
                <TextField source="fileSize" label="File Size (bytes)" />
                <CustomDateField source="uploadDate" label="Uploaded At" format="LLLL" />
                <FunctionField
                    label="Actions"
                    render={(record) => (
                        <button
                            onClick={() => handleDownload(record.id)}
                            style={{
                                padding: "5px 10px",
                                backgroundColor: "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                            }}
                        >
                            Download
                        </button>
                    )}
                />
            </Datagrid>
        </List>
    );
};

const filters = [
    <TextInput label="Search filename" source="name" alwaysOn />,
];