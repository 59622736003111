import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

const CustomDateField = ({ source, format, label, record = {} }) => {
  let dateFormat = "LLLL";
  if (format != null) {
    dateFormat = format;
  }

  let value = record[source];
  if (source.includes(".")) {
    let parts = source.split(".");
    value = parts.reduce((prev, curr) => prev && prev[curr], record);
  }

  const normalizeDate = (date) => {
    if (!date) return null;

    if (typeof date === "string" && date.includes(" ")) {
      return date.replace(" ", "T").split(".")[0];
    }

    if (typeof date === "object" && date.year && date.monthValue && date.dayOfMonth) {
      return new Date(
          date.year,
          date.monthValue - 1,
          date.dayOfMonth,
          date.hour || 0,
          date.minute || 0,
          date.second || 0,
          Math.floor(date.nano / 1e6)
      ).toISOString();
    }

    return date;
  };

  const normalizedValue = normalizeDate(value);

  const isValidDate = (date) => {
    return date && !isNaN(new Date(date).getTime());
  };

  if (!isValidDate(normalizedValue)) {
    return <span style={{ color: "red" }}>Invalid date</span>
  }

  return <Moment format={dateFormat}>{normalizedValue}</Moment>
};

CustomDateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  format: PropTypes.string,
};

export default CustomDateField;
